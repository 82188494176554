<template>
  <v-dialog
    v-model="updateShieldModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Editar Escudo
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="mx-5 mt-5"
          lazy-validation
          @submit.prevent="updateShield()"
        >
          <v-text-field
            v-model="shield.name"
            :rules="[rules.required]"
            label="Nome de identificação do escudo"
            hint="Ex: escudo 01"
            persistent-hint
            outlined
            required
          />

          <image-uploader
            title="Clique aqui para enviar uma imagem. A imagem deve ser um SVG"
            subtitle="Tamanho recomendado: 200 por 200 pixels"
            :saving="saving"
            :savedImage="olderImage"
            :errorImage="imageOffIcon"
            :fullWidth="true"
            previewWidth="200px"
            previewHeight="200px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />

          <h2>Elementos do escudo com cores editáveis</h2>

          <div
            v-for="(el, index) in shield.elements"
            :key="`shield-element-${index}`"
            class="element-box mt-6"
          >
            <v-btn
              fab
              x-small
              color="error"
              class="delete-btn"
              @click="removeElement(index)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-text-field
              v-model="shield.elements[index].id"
              :rules="[rules.required]"
              :label="`ID do elemento ${padWithZeros(index + 1)} *`"
              hint="informe o ID do elemento encontrado no XML do SVG."
              persistent-hint
              outlined
              required
            />

            <v-text-field
              v-model="shield.elements[index].name"
              :rules="[rules.required]"
              :label="`Nome do elemento ${padWithZeros(index + 1)} *`"
              hint="O nome do elemento será mostrado para o usuário. Ex: 'Borda'."
              persistent-hint
              outlined
              required
            />

            <v-text-field
              v-model="shield.elements[index].description"
              :label="`Descrição do elemento ${padWithZeros(index + 1)}`"
              hint="Descrição do elemento, para caso o usuário tenha dúvidas.'"
              persistent-hint
              outlined
            />
          </div>

          <v-btn color="secondary" class="my-5" @click="addElement()">
            <v-icon class="mr-1">mdi-plus</v-icon> Adicionar elemento
          </v-btn>
          <v-divider class="mt-1" />

          <div v-if="((image && previewSVG) || olderImage) && shield.elements.length > 0 && shield.elements[0].id" class="mt-4">
            <h2>Exemplo do escudo modificado por um usuário:</h2>
            <h3>Se esta imagem estiver igual a original, verifique os ID's informados.</h3>
            <inline-svg
              :src="previewSVG || `${olderImage}?x-request=xhr`"
              :transformSource="transformSVG"
              class="svg-preview mt-4"
            />
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="updateShield()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

const imageOffIcon = require('@/assets/icons/image-off.png')
export default {
  props: {
    shieldData: {
      type: Object,
      required: true
    }
  },
  components: {
    ImageUploader: () => import('../../../components/ImageUploader.vue'),
    InlineSvg
  },
  data () {
    return {
      imageOffIcon,
      saving: false,
      valid: true,
      shield: {
        name: '',
        imageType: undefined,
        elements: []
      },
      id: null,
      previewSVG: null,
      image: null,
      olderImage: null,
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    updateShieldModal: {
      get () {
        return this.$store.state.updateShieldModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateShieldModal',
          value: val
        })
      }
    }
  },
  watch: {
    updateShieldModal (val) {
      if (val) {
        const { _id, name, image, elements } = this.shieldData
        this.id = _id
        this.image = image
        this.olderImage = image

        this.shield.name = name
        this.shield.elements = elements.map(el => {
          el._id = undefined
          return el
        })
      } else {
        this.shield = {
          name: '',
          imageType: undefined,
          elements: []
        }
        this.id = null
        this.image = null
        this.olderImage = null
        this.previewSVG = null
      }
    }
  },
  methods: {
    updateShield () {
      if (this.$refs.form.validate()) {
        if (this.shield.imageType && this.shield.imageType !== 'image/svg+xml') {
          this.$toast.error('A imagem deve ser um SVG.')
          return
        }

        if (this.shield.elements.length === 0) {
          this.$toast.error('Informe pelo menos um elemento editável.')
          return
        }

        if (this.image || this.shield.imageType) {
          this.saving = true
          this.$http.put(`/shields/${this.id}`, this.shield)
            .then((res) => {
              const { data } = res
              if (data.putUrl) {
                this.uploadImage(data.putUrl, this.image, this.success, this.error)
              } else {
                this.success()
              }
            })
            .catch((err) => {
              this.saving = false
              this.$toast.error(err.response.data.message)
            })
        } else {
          this.$toast.error('É necessário enviar uma imagem do escudo.')
        }
      }
    },
    success () {
      this.saving = false
      this.updateShieldModal = false
      this.$emit('created')
      this.$toast.success('Cadastro atualizado com sucesso.')
    },
    error () {
      this.saving = false
      this.updateShieldModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    addElement () {
      this.shield.elements.push({
        id: '',
        name: '',
        description: ''
      })
    },
    removeElement (index) {
      this.shield.elements.splice(index, 1)
    },
    handleImage (file) {
      if (file.type !== 'image/svg+xml') {
        this.$toast.warning('A imagem deve ser um SVG.')
      }
      this.shield.imageType = file.type
      this.image = file

      var reader = new FileReader()
      var _this = this
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        _this.previewSVG = e.target.result
      }
    },
    handleRemoveImage () {
      this.shield.imageType = undefined
      this.image = null
      this.olderImage = null
    },
    transformSVG (svg) {
      for (let index = 0; index < this.shield.elements.length; index++) {
        // eslint-disable-next-line prefer-const
        let element = this.shield.elements[index]
        // eslint-disable-next-line prefer-const
        let svgElement = svg.getElementById(element.id)
        if (svgElement) {
          svgElement.setAttribute('fill', this.randomColor())
        }
      }
      return svg
    }
  }
}
</script>

<style scoped>
.element-box {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
  padding: 40px;
  position: relative;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 5;
}

.delete-btn:hover {
  transform: scale(1.1);
}

.svg-preview {
  width: 200px;
  height: 200px;
}
</style>
